"use client"

import React from "react"
import { PropertyTypeEnum } from "@/db/schemas/enums"

import { capitalize } from "@/lib/utils"
import { Label } from "@/components/ui/label"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"

type PropertyTypeSelectProps = {
  value?: string
  onValueChange: (value: PropertyTypeEnum) => void
}

export function PropertyTypeSelect({
  value,
  onValueChange,
}: PropertyTypeSelectProps) {
  return (
    <div>
      <Label className="pb-2">Tipo de inmueble</Label>
      <div className="m-1">
        <Select value={value} onValueChange={onValueChange}>
          <SelectTrigger className="w-full">
            <SelectValue placeholder="Tipo de inmueble" />
          </SelectTrigger>
          <SelectContent>
            {Object.values(PropertyTypeEnum.Values).map((value) => (
              <SelectItem key={value} value={value}>
                {value === "habitacion"
                  ? "Habitación"
                  : capitalize(value).replaceAll("-", " ")}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </div>
  )
}
