"use client"

import React from "react"
import { PropertyTypeEnum, TransactionTypeEnum } from "@/db/schemas/enums"

import { capitalize } from "@/lib/utils"
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs"

type TransactionTypeTabsProps = {
  value: string
  onValueChange: (value: string) => void
  propertyType: PropertyTypeEnum
}

export function TransactionTypeTabs({
  value,
  onValueChange,
  propertyType,
}: TransactionTypeTabsProps) {
  return (
    <Tabs value={value} onValueChange={onValueChange} className="w-full">
      <TabsList className="w-full">
        {Object.values(TransactionTypeEnum.Values).map((value) => (
          <TabsTrigger
            key={value}
            value={value}
            className="flex-1"
            disabled={value === "venta" && propertyType === "habitacion"}
          >
            {capitalize(value)}
          </TabsTrigger>
        ))}
      </TabsList>
    </Tabs>
  )
}
