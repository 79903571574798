import { TransactionTypeEnum } from "@/db/schemas/enums"

export const config = {
  ["price"]: {
    [TransactionTypeEnum.Enum.alquiler]: {
      ["soles"]: [
        { label: null, value: undefined },
        { label: "400", value: 400 },
        { label: "600", value: 600 },
        { label: "800", value: 800 },
        { label: "1,000", value: 1000 },
        { label: "1,200", value: 1200 },
        { label: "1,400", value: 1400 },
        { label: "1,600", value: 1600 },
        { label: "1,800", value: 1800 },
        { label: "2,000", value: 2000 },
        { label: "2,200", value: 2200 },
        { label: "2,400", value: 2400 },
        { label: "2,600", value: 2600 },
        { label: "2,800", value: 2800 },
        { label: "3,000", value: 3000 },
        { label: "3,200", value: 3200 },
        { label: "3,400", value: 3400 },
        { label: "3,600", value: 3600 },
        { label: "3,800", value: 3800 },
        { label: "4,000", value: 4000 },
        { label: "4,400", value: 4400 },
        { label: "4,800", value: 4800 },
        { label: "5,200", value: 5200 },
        { label: "5,600", value: 5600 },
        { label: "6,000", value: 6000 },
        { label: "6,400", value: 6400 },
        { label: "6,800", value: 6800 },
        { label: "7,200", value: 7200 },
        { label: "7,600", value: 7600 },
        { label: "8,000", value: 8000 },
        { label: "8,400", value: 8400 },
        { label: "9,600", value: 9600 },
        { label: "10,800", value: 10800 },
        { label: "12,000", value: 12000 },
        { label: "Sin límite", value: Infinity },
      ],
      ["dolares"]: [
        { label: null, value: undefined },
        { label: "100", value: 100 },
        { label: "150", value: 150 },
        { label: "200", value: 200 },
        { label: "250", value: 250 },
        { label: "300", value: 300 },
        { label: "350", value: 350 },
        { label: "400", value: 400 },
        { label: "450", value: 450 },
        { label: "500", value: 500 },
        { label: "550", value: 550 },
        { label: "600", value: 600 },
        { label: "650", value: 650 },
        { label: "700", value: 700 },
        { label: "750", value: 750 },
        { label: "800", value: 800 },
        { label: "850", value: 850 },
        { label: "900", value: 900 },
        { label: "950", value: 950 },
        { label: "1,000", value: 1000 },
        { label: "1,100", value: 1100 },
        { label: "1,200", value: 1200 },
        { label: "1,300", value: 1300 },
        { label: "1,400", value: 1400 },
        { label: "1,500", value: 1500 },
        { label: "1,600", value: 1600 },
        { label: "1,700", value: 1700 },
        { label: "1,800", value: 1800 },
        { label: "1,900", value: 1900 },
        { label: "2,000", value: 2000 },
        { label: "2,100", value: 2100 },
        { label: "2,400", value: 2400 },
        { label: "2,700", value: 2700 },
        { label: "3,000", value: 3000 },
        { label: "Sin límite", value: Infinity },
      ],
    },
    [TransactionTypeEnum.Enum.venta]: {
      ["soles"]: [
        { label: null, value: undefined },
        { label: "240,000", value: 240000 },
        { label: "320,000", value: 320000 },
        { label: "400,000", value: 400000 },
        { label: "480,000", value: 480000 },
        { label: "560,000", value: 560000 },
        { label: "600,000", value: 600000 },
        { label: "640,000", value: 640000 },
        { label: "720,000", value: 720000 },
        { label: "800,000", value: 800000 },
        { label: "880,000", value: 880000 },
        { label: "960,000", value: 960000 },
        { label: "1,04 millones", value: 1040000 },
        { label: "1,1 millones", value: 1120000 },
        { label: "1,2 millones", value: 1200000 },
        { label: "1,2 millones", value: 1280000 },
        { label: "1,3 millones", value: 1360000 },
        { label: "1,4 millones", value: 1440000 },
        { label: "1,5 millones", value: 1520000 },
        { label: "1,6 millones", value: 1600000 },
        { label: "1,8 millones", value: 1800000 },
        { label: "2,0 millones", value: 2000000 },
        { label: "2,2 millones", value: 2200000 },
        { label: "2,4 millones", value: 2400000 },
        { label: "2,6 millones", value: 2600000 },
        { label: "2,8 millones", value: 2800000 },
        { label: "3,0 millones", value: 3000000 },
        { label: "3,2 millones", value: 3200000 },
        { label: "3,4 millones", value: 3400000 },
        { label: "3,6 millones", value: 3600000 },
        { label: "3,8 millones", value: 3800000 },
        { label: "4,0 millones", value: 4000000 },
        { label: "4,4 millones", value: 4400000 },
        { label: "4,8 millones", value: 4800000 },
        { label: "5,2 millones", value: 5200000 },
        { label: "5,6 millones", value: 5600000 },
        { label: "6,0 millones", value: 6000000 },
        { label: "6,4 millones", value: 6400000 },
        { label: "6,8 millones", value: 6800000 },
        { label: "7,2 millones", value: 7200000 },
        { label: "7,6 millones", value: 7600000 },
        { label: "8,0 millones", value: 8000000 },
        { label: "8,4 millones", value: 8400000 },
        { label: "8,8 millones", value: 8800000 },
        { label: "9,2 millones", value: 9200000 },
        { label: "9,6 millones", value: 9600000 },
        { label: "10 millones", value: 10000000 },
        { label: "10,4 millones", value: 10400000 },
        { label: "10,8 millones", value: 10800000 },
        { label: "11,2 millones", value: 11200000 },
        { label: "11,6 millones", value: 11600000 },
        { label: "12 millones", value: 12000000 },
        { label: "Sin límite", value: undefined },
      ],
      ["dolares"]: [
        { label: null, value: undefined },
        { label: "60,000", value: 60000 },
        { label: "80,000", value: 80000 },
        { label: "100,000", value: 100000 },
        { label: "120,000", value: 120000 },
        { label: "140,000", value: 140000 },
        { label: "150,000", value: 150000 },
        { label: "160,000", value: 160000 },
        { label: "180,000", value: 180000 },
        { label: "200,000", value: 200000 },
        { label: "220,000", value: 220000 },
        { label: "240,000", value: 240000 },
        { label: "260,000", value: 260000 },
        { label: "280,000", value: 280000 },
        { label: "300,000", value: 300000 },
        { label: "320,000", value: 320000 },
        { label: "340,000", value: 340000 },
        { label: "360,000", value: 360000 },
        { label: "380,000", value: 380000 },
        { label: "400,000", value: 400000 },
        { label: "450,000", value: 450000 },
        { label: "500,000", value: 500000 },
        { label: "550,000", value: 550000 },
        { label: "600,000", value: 600000 },
        { label: "650,000", value: 650000 },
        { label: "700,000", value: 700000 },
        { label: "750,000", value: 750000 },
        { label: "800,000", value: 800000 },
        { label: "850,000", value: 850000 },
        { label: "900,000", value: 900000 },
        { label: "950,000", value: 950000 },
        { label: "1 millón", value: 1000000 },
        { label: "1,1 millones", value: 1100000 },
        { label: "1,2 millones", value: 1200000 },
        { label: "1,3 millones", value: 1300000 },
        { label: "1,4 millones", value: 1400000 },
        { label: "1,5 millones", value: 1500000 },
        { label: "1,6 millones", value: 1600000 },
        { label: "1,7 millones", value: 1700000 },
        { label: "1,8 millones", value: 1800000 },
        { label: "1,9 millones", value: 1900000 },
        { label: "2 millones", value: 2000000 },
        { label: "2,1 millones", value: 2100000 },
        { label: "2,2 millones", value: 2200000 },
        { label: "2,3 millones", value: 2300000 },
        { label: "2,4 millones", value: 2400000 },
        { label: "2,5 millones", value: 2500000 },
        { label: "2,6 millones", value: 2600000 },
        { label: "2,7 millones", value: 2700000 },
        { label: "2,8 millones", value: 2800000 },
        { label: "2,9 millones", value: 2900000 },
        { label: "3 millones", value: 3000000 },
        { label: "Sin límite", value: undefined },
      ],
    },
  },
  ["area"]: {
    [TransactionTypeEnum.Enum.alquiler]: {
      ["total"]: [
        { label: null, value: undefined },
        { label: "30", value: 30 },
        { label: "40", value: 40 },
        { label: "50", value: 50 },
        { label: "60", value: 60 },
        { label: "70", value: 70 },
        { label: "80", value: 80 },
        { label: "90", value: 90 },
        { label: "100", value: 100 },
        { label: "110", value: 110 },
        { label: "120", value: 120 },
        { label: "130", value: 130 },
        { label: "140", value: 140 },
        { label: "150", value: 150 },
        { label: "160", value: 160 },
        { label: "170", value: 170 },
        { label: "180", value: 180 },
        { label: "190", value: 190 },
        { label: "200", value: 200 },
        { label: "210", value: 210 },
        { label: "220", value: 220 },
        { label: "230", value: 230 },
        { label: "240", value: 240 },
        { label: "250", value: 250 },
        { label: "260", value: 260 },
        { label: "270", value: 270 },
        { label: "280", value: 280 },
        { label: "290", value: 290 },
        { label: "300", value: 300 },
        { label: "310", value: 310 },
        { label: "320", value: 320 },
        { label: "330", value: 330 },
        { label: "340", value: 340 },
        { label: "350", value: 350 },
        { label: "360", value: 360 },
        { label: "370", value: 370 },
        { label: "380", value: 380 },
        { label: "390", value: 390 },
        { label: "400", value: 400 },
        { label: "410", value: 410 },
        { label: "420", value: 420 },
        { label: "Sin límite", value: Infinity },
      ],
      ["techado"]: [
        { label: null, value: undefined },
        { label: "30", value: 30 },
        { label: "40", value: 40 },
        { label: "50", value: 50 },
        { label: "60", value: 60 },
        { label: "70", value: 70 },
        { label: "80", value: 80 },
        { label: "90", value: 90 },
        { label: "100", value: 100 },
        { label: "110", value: 110 },
        { label: "120", value: 120 },
        { label: "130", value: 130 },
        { label: "140", value: 140 },
        { label: "150", value: 150 },
        { label: "160", value: 160 },
        { label: "170", value: 170 },
        { label: "180", value: 180 },
        { label: "190", value: 190 },
        { label: "200", value: 200 },
        { label: "210", value: 210 },
        { label: "220", value: 220 },
        { label: "230", value: 230 },
        { label: "240", value: 240 },
        { label: "250", value: 250 },
        { label: "260", value: 260 },
        { label: "270", value: 270 },
        { label: "280", value: 280 },
        { label: "290", value: 290 },
        { label: "300", value: 300 },
        { label: "310", value: 310 },
        { label: "320", value: 320 },
        { label: "330", value: 330 },
        { label: "340", value: 340 },
        { label: "350", value: 350 },
        { label: "360", value: 360 },
        { label: "370", value: 370 },
        { label: "380", value: 380 },
        { label: "390", value: 390 },
        { label: "400", value: 400 },
        { label: "410", value: 410 },
        { label: "420", value: 420 },
        { label: "Sin límite", value: Infinity },
      ],
    },
    [TransactionTypeEnum.Enum.venta]: {
      ["total"]: [
        { label: null, value: undefined },
        { label: "30", value: 30 },
        { label: "40", value: 40 },
        { label: "50", value: 50 },
        { label: "60", value: 60 },
        { label: "70", value: 70 },
        { label: "80", value: 80 },
        { label: "90", value: 90 },
        { label: "100", value: 100 },
        { label: "110", value: 110 },
        { label: "120", value: 120 },
        { label: "130", value: 130 },
        { label: "140", value: 140 },
        { label: "150", value: 150 },
        { label: "160", value: 160 },
        { label: "170", value: 170 },
        { label: "180", value: 180 },
        { label: "190", value: 190 },
        { label: "200", value: 200 },
        { label: "210", value: 210 },
        { label: "220", value: 220 },
        { label: "230", value: 230 },
        { label: "240", value: 240 },
        { label: "250", value: 250 },
        { label: "260", value: 260 },
        { label: "270", value: 270 },
        { label: "280", value: 280 },
        { label: "290", value: 290 },
        { label: "300", value: 300 },
        { label: "310", value: 310 },
        { label: "320", value: 320 },
        { label: "330", value: 330 },
        { label: "340", value: 340 },
        { label: "350", value: 350 },
        { label: "360", value: 360 },
        { label: "370", value: 370 },
        { label: "380", value: 380 },
        { label: "390", value: 390 },
        { label: "400", value: 400 },
        { label: "410", value: 410 },
        { label: "420", value: 420 },
        { label: "Sin límite", value: Infinity },
      ],
      ["techado"]: [
        { label: null, value: undefined },
        { label: "30", value: 30 },
        { label: "40", value: 40 },
        { label: "50", value: 50 },
        { label: "60", value: 60 },
        { label: "70", value: 70 },
        { label: "80", value: 80 },
        { label: "90", value: 90 },
        { label: "100", value: 100 },
        { label: "110", value: 110 },
        { label: "120", value: 120 },
        { label: "130", value: 130 },
        { label: "140", value: 140 },
        { label: "150", value: 150 },
        { label: "160", value: 160 },
        { label: "170", value: 170 },
        { label: "180", value: 180 },
        { label: "190", value: 190 },
        { label: "200", value: 200 },
        { label: "210", value: 210 },
        { label: "220", value: 220 },
        { label: "230", value: 230 },
        { label: "240", value: 240 },
        { label: "250", value: 250 },
        { label: "260", value: 260 },
        { label: "270", value: 270 },
        { label: "280", value: 280 },
        { label: "290", value: 290 },
        { label: "300", value: 300 },
        { label: "310", value: 310 },
        { label: "320", value: 320 },
        { label: "330", value: 330 },
        { label: "340", value: 340 },
        { label: "350", value: 350 },
        { label: "360", value: 360 },
        { label: "370", value: 370 },
        { label: "380", value: 380 },
        { label: "390", value: 390 },
        { label: "400", value: 400 },
        { label: "410", value: 410 },
        { label: "420", value: 420 },
        { label: "Sin límite", value: Infinity },
      ],
    },
  },
}
