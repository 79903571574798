import { Toggle } from "@/components/ui/toggle"
import { Icons } from "@/components/icons"

import { ExtraItem } from "../extras-picker"

type ExtraToggleProps = {
  item: ExtraItem
  onPressedChange: (item: ExtraItem) => void
}

export function ExtraToggle({ item, onPressedChange }: ExtraToggleProps) {
  const Icon = Icons[item.icon] ?? "chevronLeft"
  return (
    <Toggle
      key={item.key}
      variant="outline"
      size="lg"
      className="flex w-full items-center justify-start gap-x-4 pl-6"
      pressed={item.value}
      onPressedChange={() => onPressedChange(item)}
    >
      <Icon className="size-5" />
      <p className="text-left">{item.label}</p>
    </Toggle>
  )
}
