import {
  // AvailableFromEnum,
  BathroomFeaturesEnum,
  BedroomBathroomFeaturesEnum,
  BedroomFeaturesEnum,
  BedroomIsFurnishedFeaturesEnum,
  CommercialActivityEnum,
  CommercialLocationEnum,
  CouplesAllowedFeaturesEnum,
  ExtrasFeaturesEnum,
  FurnishedFeaturesEnum,
  MinorsAllowedFeaturesEnum,
  ParkingFeaturesEnum,
  PetsFeaturesEnum,
  PropertyConditionEnum,
  RoomsFeaturesEnum,
  SmokersFeaturesEnum,
  TotalFloorsFeaturesEnum,
  // TypeOfBedFeaturesEnum,
} from "@/db/schemas/enums"
import { SortOptionEnum } from "@/types"

import { Icons } from "@/components/icons"

type SearchConfigType = {
  bedroomsConfig: { key: BedroomFeaturesEnum; label: string }[]
  bathroomsConfig: { key: BathroomFeaturesEnum; label: string }[]
  parkingsConfig: { key: ParkingFeaturesEnum; label: string }[]
  extrasConfig: {
    key: ExtrasFeaturesEnum
    label: string
    icon: keyof typeof Icons
  }[]
  furnishedConfig: { key: FurnishedFeaturesEnum; label: string }[]
  sortConfig: { key: SortOptionEnum; label: string }[]
  commercialLocationConfig: { key: CommercialLocationEnum; label: string }[]
  commercialActivityConfig: { key: CommercialActivityEnum; label: string }[]
  conditionConfig: { key: PropertyConditionEnum; label: string }[]
  smokersConfig: { key: SmokersFeaturesEnum; label: string }[]
  petsConfig: { key: PetsFeaturesEnum; label: string }[]
  couplesConfig: { key: CouplesAllowedFeaturesEnum; label: string }[]
  minorsConfig: { key: MinorsAllowedFeaturesEnum; label: string }[]
  // typeOfBedConfig: { key: TypeOfBedFeaturesEnum; label: string }[]
  bedroomIsFurnishedConfig: {
    key: BedroomIsFurnishedFeaturesEnum
    label: string
  }[]
  bedroomBathroomConfig: {
    key: BedroomBathroomFeaturesEnum
    label: string
  }[]
  // availableFromConfig: { key: AvailableFromEnum; label: string }[]
  roomsConfig?: { key: string; label: string }[]
  totalFloorsConfig?: { key: string; label: string }[]
  genderDesiredConfig?: { key: string; label: string }[]
}

export const SearchConfig = {
  bedroomsConfig: [
    {
      key: BedroomFeaturesEnum.Values["0-dormitorios"],
      label: "0",
    },
    {
      key: BedroomFeaturesEnum.Values["1-dormitorio"],
      label: "1",
    },
    {
      key: BedroomFeaturesEnum.Values["2-dormitorios"],
      label: "2",
    },
    {
      key: BedroomFeaturesEnum.Values["3-dormitorios"],
      label: "3",
    },
    {
      key: BedroomFeaturesEnum.Values["mas-de-4-dormitorios"],
      label: "+4",
    },
  ],
  bathroomsConfig: [
    {
      key: BathroomFeaturesEnum.Values["0-banos"],
      label: "0",
    },
    {
      key: BathroomFeaturesEnum.Values["1-bano"],
      label: "1",
    },
    {
      key: BathroomFeaturesEnum.Values["2-banos"],
      label: "2",
    },
    {
      key: BathroomFeaturesEnum.Values["3-banos"],
      label: "3",
    },
    {
      key: BathroomFeaturesEnum.Values["mas-de-4-banos"],
      label: "+4",
    },
  ],
  parkingsConfig: [
    {
      key: ParkingFeaturesEnum.Values["0-estacionamientos"],
      label: "0",
    },
    {
      key: ParkingFeaturesEnum.Values["1-estacionamiento"],
      label: "1",
    },
    {
      key: ParkingFeaturesEnum.Values["2-estacionamientos"],
      label: "2",
    },
    {
      key: ParkingFeaturesEnum.Values["3-estacionamientos"],
      label: "3",
    },
    {
      key: ParkingFeaturesEnum.Values["mas-de-4-estacionamientos"],
      label: "+4",
    },
  ],
  extrasConfig: [
    {
      key: ExtrasFeaturesEnum.Values["ascensor"],
      icon: "scanEye",
      label: "Ascensor",
    },
    {
      key: ExtrasFeaturesEnum.Values["admite-mascotas"],
      icon: "dog",
      label: "Admite mascotas",
    },
    {
      key: ExtrasFeaturesEnum.Values["balcon"],
      icon: "bookImage",
      label: "Balcón",
    },
    {
      key: ExtrasFeaturesEnum.Values["terraza"],
      icon: "scan",
      label: "Balcón/Terraza",
    },
    {
      key: ExtrasFeaturesEnum.Values["videovigilancia"],
      icon: "video",
      label: "Videovigilancia",
    },
    {
      key: ExtrasFeaturesEnum.Values["vigilancia"],
      icon: "shieldCheck",
      label: "Vigilancia",
    },
    {
      key: ExtrasFeaturesEnum.Values["jardin"],
      icon: "treeDeciduous",
      label: "Jardín",
    },
    {
      key: ExtrasFeaturesEnum.Values["piscina"],
      icon: "waves",
      label: "Piscina",
    },
    {
      key: ExtrasFeaturesEnum.Values["lavanderia"],
      icon: "shirt",
      label: "Lavandería",
    },
    {
      key: ExtrasFeaturesEnum.Values["deposito-trastero"],
      icon: "warehouse",
      label: "Depósito",
    },
    {
      key: ExtrasFeaturesEnum.Values["lujo"],
      icon: "gem",
      label: "Lujo",
    },
    {
      key: ExtrasFeaturesEnum.Values["vista-al-mar"],
      icon: "sailBoat",
      label: "Vista al mar",
    },
    {
      key: ExtrasFeaturesEnum.Values["en-condominio"],
      icon: "building2",
      label: "En condominio",
    },
    {
      key: ExtrasFeaturesEnum.Values["areas-comunes"],
      icon: "dumbbell",
      label: "Áreas comunes",
    },
    {
      key: ExtrasFeaturesEnum.Values["recepcion"],
      icon: "users2",
      label: "Recepción",
    },
    {
      key: ExtrasFeaturesEnum.Values["salida-de-humos"],
      icon: "flame",
      label: "Salida de humos",
    },
    {
      key: ExtrasFeaturesEnum.Values["cocina-completamente-equipada"],
      icon: "cookingPot",
      label: "Cocina equipada",
    },
    {
      key: ExtrasFeaturesEnum.Values["sistema-de-alarma"],
      icon: "siren",
      label: "Sistema de alarma",
    },
    {
      key: ExtrasFeaturesEnum.Values["detectores-de-humo"],
      icon: "alarmSmoke",
      label: "Detectores de humo",
    },
    {
      key: ExtrasFeaturesEnum.Values["area-cafe"],
      icon: "coffee",
      label: "Área de café",
    },
    {
      key: ExtrasFeaturesEnum.Values.comedor,
      icon: "utensils",
      label: "Comedor",
    },
    {
      key: ExtrasFeaturesEnum.Values["sala-multiusos"],
      icon: "tv",
      label: "Sala multiusos",
    },
    {
      key: ExtrasFeaturesEnum.Values["aire-acondicionado"],
      icon: "airVent",
      label: "Aire acondicionado",
    },
    {
      key: ExtrasFeaturesEnum.Values["adaptado-movilidad-reducida"],
      icon: "accessibility",
      label: "Adaptado a movilidad reducida",
    },
    {
      key: ExtrasFeaturesEnum.Values["estacionamiento-bicicletas"],
      icon: "bike",
      label: "Estacionamiento de bicicletas",
    },
    {
      key: ExtrasFeaturesEnum.Values["limpieza-incluida"],
      icon: "sparkles",
      label: "Limpieza incluida",
    },
    {
      key: ExtrasFeaturesEnum.Values["internet"],
      icon: "wifi",
      label: "Conexión a Internet",
    },
    {
      key: ExtrasFeaturesEnum.Values["lgtb-friendly"],
      icon: "rainbow",
      label: "LGTB Friendly",
    },
    // {
    //   key: ExtrasFeaturesEnum.Values["habitacion-amueblada"],
    //   icon: "bed",
    //   label: "Habitación amueblada",
    // },
    // {
    //   key: ExtrasFeaturesEnum.Values["armario-empotrado"],
    //   icon: "columns3",
    //   label: "Armario empotrado",
    // },
    // {
    //   key: ExtrasFeaturesEnum.Values["baño-privado"],
    //   icon: "bath",
    //   label: "Baño privado",
    // },
  ],
  furnishedConfig: [
    {
      key: FurnishedFeaturesEnum.Values["sin-amoblar"],
      label: "Amoblado",
    },
  ],
  sortConfig: [
    {
      key: SortOptionEnum.enum.relevancia,
      label: "Relevancia",
    },
    {
      key: SortOptionEnum.enum.baratos,
      label: "Más baratos",
    },
    {
      key: SortOptionEnum.enum.caros,
      label: "Más caros",
    },
    {
      key: SortOptionEnum.enum.recientes,
      label: "Más recientes",
    },
    {
      key: SortOptionEnum.enum.antiguos,
      label: "Más antiguos",
    },
  ],
  commercialLocationConfig: [
    {
      key: CommercialLocationEnum.Values["en-edificio"],
      label: "En edificio",
    },
    {
      key: CommercialLocationEnum.Values["en-centro-comercial"],
      label: "En centro comercial",
    },
    {
      key: CommercialLocationEnum.Values["en-galeria"],
      label: "En galería",
    },
    {
      key: CommercialLocationEnum.Values["en-avenida-o-calle"],
      label: "En Avenida/Calle",
    },
    {
      key: CommercialLocationEnum.Values["sotano"],
      label: "Sótano",
    },
    {
      key: CommercialLocationEnum.Values["otros"],
      label: "Otros",
    },
  ],
  commercialActivityConfig: [
    {
      key: CommercialActivityEnum.Values["bed-&-breakfast"],
      label: "Bed & Breakfast",
    },
    {
      key: CommercialActivityEnum.Values["hotel"],
      label: "Hotel",
    },
    {
      key: CommercialActivityEnum.Values["negocio-de-turismo-rural"],
      label: "Negocio de turismo rural",
    },
    {
      key: CommercialActivityEnum.Values["otros-tipos-de-alojamiento"],
      label: "Otros tipos de alojamiento",
    },
    {
      key: CommercialActivityEnum.Values["bar"],
      label: "Bar",
    },
    {
      key: CommercialActivityEnum.Values["bar-de-copas"],
      label: "Bar de copas",
    },
    {
      key: CommercialActivityEnum.Values["cafeteria"],
      label: "Cafetería",
    },
    {
      key: CommercialActivityEnum.Values["discoteca"],
      label: "Discoteca",
    },
    {
      key: CommercialActivityEnum.Values["pub"],
      label: "Pub",
    },
    {
      key: CommercialActivityEnum.Values["asador"],
      label: "Asador",
    },
    {
      key: CommercialActivityEnum.Values["bocateria"],
      label: "Bocatería",
    },
    {
      key: CommercialActivityEnum.Values["chiringuito"],
      label: "Chiringuito",
    },
    {
      key: CommercialActivityEnum.Values["pizzeria"],
      label: "Pizzería",
    },
    {
      key: CommercialActivityEnum.Values["restaurante"],
      label: "Restaurante",
    },
    {
      key: CommercialActivityEnum.Values["take-away"],
      label: "Take away",
    },
    {
      key: CommercialActivityEnum.Values["otros-tipos-de-restauracion"],
      label: "Otros tipos de restauración",
    },
    {
      key: CommercialActivityEnum.Values["carniceria"],
      label: "Carnicería",
    },
    {
      key: CommercialActivityEnum.Values["fruteria"],
      label: "Frutería",
    },
    {
      key: CommercialActivityEnum.Values["heladeria"],
      label: "Heladería",
    },
    {
      key: CommercialActivityEnum.Values["panaderia"],
      label: "Panadería",
    },
    {
      key: CommercialActivityEnum.Values["pasteleria"],
      label: "Pastelería",
    },
    {
      key: CommercialActivityEnum.Values["supermercado"],
      label: "Supermercado",
    },
    {
      key: CommercialActivityEnum.Values["tienda-de-alimentacion"],
      label: "Tienda de alimentación",
    },
    {
      key: CommercialActivityEnum.Values["vinoteca"],
      label: "Vinoteca",
    },
    {
      key: CommercialActivityEnum.Values[
        "otros-tipos-de-comercio-de-alimentacion"
      ],
      label: "Otros tipos de comercio de alimentación",
    },
    {
      key: CommercialActivityEnum.Values["estanco"],
      label: "Estanco",
    },
    {
      key: CommercialActivityEnum.Values["farmacia"],
      label: "Farmacia",
    },
    {
      key: CommercialActivityEnum.Values["kiosco"],
      label: "Kiosco",
    },
    {
      key: CommercialActivityEnum.Values["concesionario"],
      label: "Concesionario",
    },
    {
      key: CommercialActivityEnum.Values["ferreteria"],
      label: "Ferretería",
    },
    {
      key: CommercialActivityEnum.Values["floristeria"],
      label: "Floristería",
    },
    {
      key: CommercialActivityEnum.Values["herbolario"],
      label: "Herbolario",
    },
    {
      key: CommercialActivityEnum.Values["papeleria"],
      label: "Papelería",
    },
    {
      key: CommercialActivityEnum.Values["tienda-de-cosmetica-y-perfumeria"],
      label: "Tienda de cosmética y perfumería",
    },
    {
      key: CommercialActivityEnum.Values["tienda-de-electronica-e-informatica"],
      label: "Tienda de electrónica e informática",
    },
    {
      key: CommercialActivityEnum.Values["tienda-de-menaje-de-hogar"],
      label: "Tienda de menaje de hogar",
    },
    {
      key: CommercialActivityEnum.Values["tienda-de-ropa"],
      label: "Tienda de ropa",
    },
    {
      key: CommercialActivityEnum.Values["otros-tipos-de-tienda"],
      label: "Otros tipos de tienda",
    },
    {
      key: CommercialActivityEnum.Values["centro-educativo"],
      label: "Centro educativo",
    },
    {
      key: CommercialActivityEnum.Values["centro-estetico"],
      label: "Centro estético",
    },
    {
      key: CommercialActivityEnum.Values["peluqueria"],
      label: "Peluquería",
    },
    {
      key: CommercialActivityEnum.Values["spa"],
      label: "Spa",
    },
    {
      key: CommercialActivityEnum.Values["centro-fitness"],
      label: "Centro fitness",
    },
    {
      key: CommercialActivityEnum.Values["gimnasio"],
      label: "Gimnasio",
    },
    {
      key: CommercialActivityEnum.Values["agencia-inmobiliaria"],
      label: "Agencia inmobiliaria",
    },
    {
      key: CommercialActivityEnum.Values["otras-clinicas"],
      label: "Otras clínicas",
    },
    {
      key: CommercialActivityEnum.Values["taller"],
      label: "Taller",
    },
    {
      key: CommercialActivityEnum.Values[
        "otros-tipos-de-servicios-profesionales"
      ],
      label: "Otros tipos de servicios profesionales",
    },
    {
      key: CommercialActivityEnum.Values["laboratorio"],
      label: "Laboratorio",
    },
    {
      key: CommercialActivityEnum.Values["almacen"],
      label: "Almacén",
    },
    {
      key: CommercialActivityEnum.Values["otras-actividades"],
      label: "Otras actividades",
    },
    {
      key: CommercialActivityEnum.Values["clinica-dental"],
      label: "Clínica dental",
    },
    {
      key: CommercialActivityEnum.Values["clinica-de-fisioterapia"],
      label: "Clínica de fisioterapia",
    },
    {
      key: CommercialActivityEnum.Values["clinica-veterinaria"],
      label: "Clínica veterinaria",
    },
    {
      key: CommercialActivityEnum.Values["empresa-agricola"],
      label: "Empresa agrícola",
    },
  ],
  conditionConfig: [
    {
      key: PropertyConditionEnum.Values.bueno,
      label: "Bueno",
    },
    {
      key: PropertyConditionEnum.Values["a-reformar"],
      label: "A reformar",
    },
  ],
  smokersConfig: [
    {
      key: SmokersFeaturesEnum.Values["se-permite-fumar"],
      label: "Se puede fumar",
    },
    {
      key: SmokersFeaturesEnum.Values["no-se-permite-fumar"],
      label: "Prohibido fumar",
    },
  ],
  couplesConfig: [
    {
      key: CouplesAllowedFeaturesEnum.Values["se-aceptan-parejas"],
      label: "Se aceptan parejas",
    },
    {
      key: CouplesAllowedFeaturesEnum.Values["no-se-aceptan-parejas"],
      label: "No se aceptan parejas",
    },
  ],
  minorsConfig: [
    {
      key: MinorsAllowedFeaturesEnum.Values["se-aceptan-menores-de-edad"],
      label: "Se aceptan menores de edad",
    },
    {
      key: MinorsAllowedFeaturesEnum.Values["no-se-aceptan-menores-de-edad"],
      label: "No se aceptan menores de edad",
    },
  ],
  petsConfig: [
    {
      key: PetsFeaturesEnum.Values["no-se-permiten-mascotas"],
      label: "No se admiten mascotas",
    },
    {
      key: PetsFeaturesEnum.Values["se-permiten-mascotas"],
      label: "Se admiten mascotas",
    },
  ],
  bedroomIsFurnishedConfig: [
    {
      key: BedroomIsFurnishedFeaturesEnum.Values["habitacion-amoblada"],
      label: "Amoblada",
    },
    {
      key: BedroomIsFurnishedFeaturesEnum.Values["habitacion-no-amoblada"],
      label: "Sin amoblar",
    },
  ],
  bedroomBathroomConfig: [
    {
      key: BedroomBathroomFeaturesEnum.Values["habitacion-con-baño-privado"],
      label: "Con baño privado",
    },
    {
      key: BedroomBathroomFeaturesEnum.Values["habitacion-con-baño-compartido"],
      label: "Con baño compartido",
    },
  ],
  // typeOfBedConfig: [
  //   {
  //     key: TypeOfBedFeaturesEnum.Values["cama-individual"],
  //     label: "Individual",
  //   },
  //   {
  //     key: TypeOfBedFeaturesEnum.Values["cama-doble"],
  //     label: "Doble",
  //   },
  //   {
  //     key: TypeOfBedFeaturesEnum.Values["dos-camas"],
  //     label: "Dos camas",
  //   },
  //   {
  //     key: TypeOfBedFeaturesEnum.Values["sin-cama"],
  //     label: "Sin cama",
  //   },
  // ],
  // availableFromConfig: [
  //   {
  //     key: AvailableFromEnum.Values["disponible-a-partir-de-ya"],
  //     label: "Ya está disponible",
  //   },
  //   {
  //     key: AvailableFromEnum.Values["disponible-a-partir-de-1-mes"],
  //     label: `${new Date(
  //       "1-" + new Date().getMonth() + 2 + new Date().getMonth() === "11"
  //         ? "-"
  //         : ""
  //     ).toLocaleString("es-ES", {
  //       month: "long",
  //     })} ${new Date().getFullYear()}`,
  //   },
  //   {
  //     key: AvailableFromEnum.Values["disponible-a-partir-de-2-meses"],
  //     label: `${(new Date().getMonth() + 2).toLocaleString("es-ES")} ${new Date().getFullYear()}`,
  //   },
  //   {
  //     key: AvailableFromEnum.Values["disponible-a-partir-de-3-meses"],
  //     label: `${(new Date().getMonth() + 3).toLocaleString("es-ES")} ${new Date().getFullYear()}`,
  //   },
  //   {
  //     key: AvailableFromEnum.Values["disponible-a-partir-de-4-meses"],
  //     label: `${(new Date().getMonth() + 4).toLocaleString("es-ES")} ${new Date().getFullYear()}`,
  //   },
  //   {
  //     key: AvailableFromEnum.Values["disponible-a-partir-de-5-meses"],
  //     label: `${(new Date().getMonth() + 5).toLocaleString("es-ES")} ${new Date().getFullYear()}`,
  //   },
  // ],
  roomsConfig: [
    {
      key: RoomsFeaturesEnum.Values["0-ambientes"],
      label: "0",
    },
    {
      key: RoomsFeaturesEnum.Values["1-ambiente"],
      label: "1",
    },
    {
      key: RoomsFeaturesEnum.Values["2-ambientes"],
      label: "2",
    },
    {
      key: RoomsFeaturesEnum.Values["3-ambientes"],
      label: "3",
    },
    {
      key: RoomsFeaturesEnum.Values["mas-de-4-ambientes"],
      label: "+4",
    },
  ],
  totalFloorsConfig: [
    {
      key: TotalFloorsFeaturesEnum.Values["1-planta"],
      label: "1",
    },
    {
      key: TotalFloorsFeaturesEnum.Values["2-plantas"],
      label: "2",
    },
    {
      key: TotalFloorsFeaturesEnum.Values["3-plantas"],
      label: "3",
    },
    {
      key: TotalFloorsFeaturesEnum.Values["mas-de-4-plantas"],
      label: "+4",
    },
  ],
  genderDesiredConfig: [
    {
      key: "genero-deseado-mujer",
      label: "Mujer",
    },
    {
      key: "genero-deseado-hombre",
      label: "Hombre",
    },
  ],
} satisfies SearchConfigType
