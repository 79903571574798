import React, { useEffect, useId } from "react"
import {
  AreaTypeEnum,
  PropertyTypeEnum,
  TransactionTypeEnum,
} from "@/db/schemas/enums"
import { SearchParamsKeyEnum } from "@/types"

import { capitalize } from "@/lib/utils"
import { SearchSearchParamsSchemaType } from "@/lib/validations/params"
import { Button } from "@/components/ui/button"
import { Label } from "@/components/ui/label"
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import { ScrollArea } from "@/components/ui/scroll-area"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { NumericInput } from "@/components/numeric-input"

import { config } from "./config"
import {
  getAreaType,
  getInitialMaxValue,
  getInitialMaxValueInput,
  getInitialMinValue,
  getInitialMinValueInput,
  getInitialUnit,
} from "./utils"

type SearchableRangeProps = {
  searchParams: SearchSearchParamsSchemaType
  type: "price" | "area"
  transactionType: TransactionTypeEnum
  onValueChange: (params: Record<string, string | number | null>) => void
  propertyType?: PropertyTypeEnum
}

export function SearchableRange({
  searchParams,
  type,
  transactionType,
  onValueChange,
  propertyType,
}: SearchableRangeProps) {
  const id = useId()
  const numericInputMinRef = React.useRef<HTMLInputElement>(null)
  const numericInputMaxRef = React.useRef<HTMLInputElement>(null)
  const [openMin, setOpenMin] = React.useState(false)
  const [openMax, setOpenMax] = React.useState(false)
  const [unit, setUnit] = React.useState<("soles" | "dolares") | AreaTypeEnum>(
    getInitialUnit(type, searchParams, propertyType)
  )

  const [minValue, setMinValue] = React.useState<string | undefined>(
    getInitialMinValue(type, searchParams)
  )
  const [minValueInput, setMinValueInput] = React.useState<string | undefined>(
    getInitialMinValueInput(type, searchParams, transactionType, unit)
  )
  const [maxValue, setMaxValue] = React.useState<string | undefined>(
    getInitialMaxValue(type, searchParams)
  )
  const [maxValueInput, setMaxValueInput] = React.useState<string | undefined>(
    getInitialMaxValueInput(type, searchParams, transactionType, unit)
  )

  const handleChangeUnit = (value: "soles" | "dolares") => {
    setUnit(value)
    setMinValue(undefined)
    setMinValueInput(undefined)
    setMaxValue(undefined)
    onValueChange({
      [type === "price"
        ? SearchParamsKeyEnum.enum.currency
        : SearchParamsKeyEnum.enum.area]: null,
      [type === "price"
        ? SearchParamsKeyEnum.enum.minPrice
        : SearchParamsKeyEnum.enum.minArea]: null,
      [type === "price"
        ? SearchParamsKeyEnum.enum.maxPrice
        : SearchParamsKeyEnum.enum.maxArea]: null,
    })
  }

  useEffect(() => {
    setMinValue(getInitialMinValue(type, searchParams))
    setMinValueInput(
      getInitialMinValueInput(type, searchParams, transactionType, unit)
    )
    setMaxValue(getInitialMaxValue(type, searchParams))
    setMaxValueInput(
      getInitialMaxValueInput(type, searchParams, transactionType, unit)
    )
    setUnit(getInitialUnit(type, searchParams, propertyType))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyType, transactionType])

  const getParamKey = React.useMemo(() => {
    if (type === "price") {
      return SearchParamsKeyEnum.enum.currency
    } else if (type === "area") {
      return SearchParamsKeyEnum.enum.area
    } else {
      return SearchParamsKeyEnum.enum.currency
    }
  }, [type])

  return (
    <div>
      <Label className="mb-2 block">
        {type === "price" ? "Precio" : "Área"}
      </Label>
      <RadioGroup
        value={unit}
        onValueChange={handleChangeUnit}
        className="flex items-center gap-4"
      >
        <div className="flex items-center space-x-2">
          <RadioGroupItem
            value={type === "price" ? "soles" : "total"}
            id={`${id}-r1`}
          />
          <Label htmlFor={`${id}-r1`}>
            {type === "price" ? "Soles" : "Total (m²)"}
          </Label>
        </div>
        <div className="flex items-center space-x-2">
          <RadioGroupItem
            value={type === "price" ? "dolares" : getAreaType(propertyType)}
            id={`${id}-r2`}
          />
          <Label htmlFor={`${id}-r2`}>
            {type === "price"
              ? "Dólares"
              : `${capitalize(getAreaType(propertyType))} (m²)`}
          </Label>
        </div>
      </RadioGroup>
      <div className="mx-1 mt-2 flex items-center gap-x-2">
        <Select
          open={openMin}
          onOpenChange={setOpenMin}
          value={String(minValue)}
          onValueChange={(value) => {
            setMinValueInput(undefined)
            setMinValue(value)
            onValueChange({
              [getParamKey]:
                value === "Infinity" || value === "undefined" ? null : unit,
              [type === "price"
                ? SearchParamsKeyEnum.enum.minPrice
                : SearchParamsKeyEnum.enum.minArea]:
                value === "Infinity" || value === "undefined" ? null : value,
            })
          }}
        >
          <SelectTrigger>
            <SelectValue asChild>
              <div>
                {minValue && !isNaN(parseInt(minValue))
                  ? parseInt(minValue).toLocaleString()
                  : "Mín"}
              </div>
            </SelectValue>
          </SelectTrigger>
          <SelectContent avoidCollisions={false} side="bottom">
            <div className="h-40 overflow-y-auto sm:max-h-96">
              <ScrollArea>
                {Object.values(
                  type === "price"
                    ? config?.["price"]?.[transactionType]?.[
                        unit === "soles" ? "soles" : "dolares"
                      ]
                    : config?.["area"]?.[transactionType]?.[
                        unit === "total" ? "total" : "techado"
                      ]
                )?.map((item) => (
                  <SelectItem
                    key={item.label}
                    value={String(item.value)}
                    className=""
                  >
                    {item.label ?? "Mín"}
                  </SelectItem>
                ))}
              </ScrollArea>
            </div>
            <div className="hidden max-w-[120px] space-y-2 border-t border-border px-2 py-2.5 lg:block">
              <NumericInput
                ref={numericInputMinRef}
                type="text"
                value={minValueInput}
                onValueChange={(values) =>
                  setMinValueInput(values.value ?? undefined)
                }
                className="w-full"
                onKeyDown={(e) => {
                  const isNumber = /^[0-9]$/i.test(e.key)
                  if (isNumber) {
                    setTimeout(() => {
                      numericInputMinRef.current?.focus()
                    }, 25)
                  }
                }}
                placeholder="Otro"
              />
              <Button
                className="w-full"
                onClick={() => {
                  setOpenMin(false)
                  setMinValue(minValueInput)
                  onValueChange({
                    [getParamKey]: unit,
                    [type === "price"
                      ? SearchParamsKeyEnum.enum.minPrice
                      : SearchParamsKeyEnum.enum.minArea]:
                      minValueInput ?? null,
                  })
                }}
              >
                OK
              </Button>
            </div>
          </SelectContent>
        </Select>

        <Select
          open={openMax}
          onOpenChange={setOpenMax}
          value={String(maxValue)}
          onValueChange={(value) => {
            setMaxValueInput(undefined)
            setMaxValue(value)
            onValueChange({
              [getParamKey]:
                value === "Infinity" || value === "undefined" ? null : unit,
              [type === "price"
                ? SearchParamsKeyEnum.enum.maxPrice
                : SearchParamsKeyEnum.enum.maxArea]:
                value === "Infinity" || value === "undefined" ? null : value,
            })
          }}
        >
          <SelectTrigger>
            <SelectValue asChild>
              <div>
                {maxValue && !isNaN(parseInt(maxValue))
                  ? parseInt(maxValue).toLocaleString()
                  : "Máx"}
              </div>
            </SelectValue>
          </SelectTrigger>
          <SelectContent avoidCollisions={false} side="bottom">
            <div className="h-40 overflow-y-auto sm:max-h-96">
              <ScrollArea>
                {Object.values(
                  type === "price"
                    ? config["price"][transactionType][
                        unit === "soles" ? "soles" : "dolares"
                      ]
                    : config["area"][transactionType][
                        unit === "total" ? "total" : "techado"
                      ]
                ).map((item) => (
                  <SelectItem
                    key={item.label}
                    value={String(item.value)}
                    className=""
                  >
                    {item.label ?? "Máx"}
                  </SelectItem>
                ))}
              </ScrollArea>
            </div>
            <div className="hidden max-w-[120px] space-y-2 border-t border-border px-2 py-2.5 lg:block">
              <NumericInput
                ref={numericInputMaxRef}
                type="text"
                value={maxValueInput}
                onValueChange={(values) =>
                  setMaxValueInput(values.value ?? undefined)
                }
                onKeyDown={(e) => {
                  const isNumber = /^[0-9]$/i.test(e.key)
                  if (isNumber) {
                    setTimeout(() => {
                      numericInputMaxRef.current?.focus()
                    }, 25)
                  }
                }}
                className="w-full"
                placeholder="Otro"
              />
              <Button
                className="w-full"
                onClick={() => {
                  setOpenMax(false)
                  setMaxValue(maxValueInput)
                  onValueChange({
                    [getParamKey]: unit,
                    [type === "price"
                      ? SearchParamsKeyEnum.enum.maxPrice
                      : SearchParamsKeyEnum.enum.maxArea]:
                      maxValueInput ?? null,
                  })
                }}
              >
                OK
              </Button>
            </div>
          </SelectContent>
        </Select>
      </div>
    </div>
  )
}
