"use client"

import React from "react"
import { SearchParamsKeyEnum } from "@/types"

import { SearchSearchParamsSchemaType } from "@/lib/validations/params"
import { Button } from "@/components/ui/button"
import { Label } from "@/components/ui/label"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { Icons } from "@/components/icons"

type FilterSelectProps = {
  label: string
  configItems: {
    key: string
    label: string
  }[]
  filterType:
    | "commercialLocation"
    | "commercialActivity"
    | "condition"
    | "smokers"
    | "pets"
    // | "typeOfBed"
    | "bedroomIsFurnished"
    | "bedroomBathroom"
    | "availableFrom"
    | "couples"
    | "minors"
    | "desiredGender"
  searchParams: SearchSearchParamsSchemaType
  onChange: (
    key: string | null,
    searchParamKey:
      | "estado"
      | "ubicacion_comercial"
      | "actividad_comercial"
      | "fumadores"
      | "mascotas"
      // | "tipo_de_cama"
      | "habitacion_amoblada"
      | "habitacion_bano"
      | "disponible_a_partir_de"
      | "parejas"
      | "menores"
      | "genero_deseado"
  ) => void
}

export function FilterSelect({
  label,
  configItems,
  filterType,
  searchParams,
  onChange,
}: FilterSelectProps) {
  const [selectedItem, setSelectedItem] = React.useState<string>(
    searchParams[SearchParamsKeyEnum.enum[filterType]] ?? ""
  )

  const handleOnChange = (key: string) => {
    onChange(key, SearchParamsKeyEnum.enum[filterType])
    setSelectedItem(key)
  }

  const onClear = () => {
    onChange(null, SearchParamsKeyEnum.enum[filterType])
    setSelectedItem("")
  }

  return (
    <div>
      <Label className="pb-2">{label}</Label>
      <div className="m-1">
        <Select value={selectedItem} onValueChange={handleOnChange}>
          <SelectTrigger className="w-full">
            <SelectValue placeholder={label} />
          </SelectTrigger>
          <SelectContent>
            {configItems.map((item) => (
              <SelectItem key={item.key} value={item.key}>
                {item.label}
              </SelectItem>
            ))}
            {selectedItem && (
              <Button
                type="button"
                size="sm"
                onClick={onClear}
                variant="ghost"
                className="w-full justify-start"
              >
                <Icons.trash className="mr-2 size-4" aria-hidden="true" />
                Limpiar
              </Button>
            )}
          </SelectContent>
        </Select>
      </div>
    </div>
  )
}
