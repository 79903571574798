"use client"

import React from "react"

import { SearchSearchParamsSchemaType } from "@/lib/validations/params"
import { Label } from "@/components/ui/label"
import { Toggle } from "@/components/ui/toggle"

export type IncrementalItem = {
  index: number
  key: string
  value: boolean
  label: string
}

type IncrementalSelectProps = {
  searchParams: SearchSearchParamsSchemaType
  label: string
  configItems: {
    key: string
    label: string
  }[]
  onChange: (items: IncrementalItem[]) => void
}

export function IncrementalSelect({
  searchParams,
  label,
  configItems,
  onChange,
}: IncrementalSelectProps) {
  const featureKeysParams = searchParams.caracteristicas
  const featureKeys = featureKeysParams?.split(",")
  const [items, setItems] = React.useState<IncrementalItem[]>(
    configItems.map((item, index) => ({
      index,
      key: item.key,
      value: featureKeys?.includes(item.key) ?? false,
      label: item.label,
    }))
  )

  function handlePressedChange(clickedItem: IncrementalItem) {
    const allItemsAreFalse = items.every((item) => !item.value)
    const trueItems = items.filter((item) => item.value)
    const clickedItemIsTrue = trueItems.some((i) => i.key === clickedItem.key)

    if (allItemsAreFalse) {
      onChange([
        ...items.map((item) =>
          item.key >= clickedItem.key
            ? { ...item, value: true }
            : { ...item, value: false }
        ),
      ])
      setItems((prev) => [
        ...prev.map((item) =>
          item.index >= clickedItem.index
            ? { ...item, value: true }
            : { ...item, value: false }
        ),
      ])
    } else {
      onChange([
        ...items.map((item) =>
          item.key === clickedItem.key
            ? { ...item, value: !clickedItemIsTrue }
            : { ...item, value: item.value }
        ),
      ])
      setItems((prev) => [
        ...prev.map((item) =>
          item.key === clickedItem.key
            ? { ...item, value: !clickedItemIsTrue }
            : { ...item, value: item.value }
        ),
      ])
    }
  }

  return (
    <div className="w-full">
      <Label className="mb-2 block">{label}</Label>
      <div className="flex w-full items-center justify-between gap-[0.2px] rounded-md">
        {items.map((item) => (
          <Toggle
            key={item.key}
            variant="close"
            className="flex-1 rounded-none"
            pressed={item.value}
            onPressedChange={() => handlePressedChange(item)}
          >
            {item.label}
          </Toggle>
        ))}
      </div>
    </div>
  )
}
