import React, { useEffect } from "react"

import { cn } from "@/lib/utils"
import { Badge } from "@/components/ui/badge"
import { buttonVariants } from "@/components/ui/button"
import { ComboboxItem, ComboboxProps } from "@/components/ui/combobox"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { ScrollArea } from "@/components/ui/scroll-area"

import { Icons } from "../icons"

export type MultiSelectComboboxProps = Omit<
  ComboboxProps<ComboboxItem>,
  "items" | "onChange" | "value"
> & {
  items: ComboboxItem[]
  selected: ComboboxItem[]
  value?: string[]
  onChange: (value: ComboboxItem[]) => void
}

export function MultiSelectCombobox({
  items,
  selected = [],
  onChange,
  inputPlaceholder,
  searchPlaceholder,
  emptyPlaceholder,
  hideSearch = false,
  sticky = false,
  size,
  hasError = false,
  className,
  ...props
}: MultiSelectComboboxProps): JSX.Element {
  const containerRef = React.useRef<HTMLDivElement>(null)
  const triggerRef = React.useRef<HTMLButtonElement>(null)
  const [comboItems, setComboItems] = React.useState<ComboboxItem[]>(items)
  const [open, setOpen] = React.useState(false)
  const [inputValue, setInputValue] = React.useState(
    items
      .filter((i) => props.value?.includes(i.value))
      .map((i) => i.label)
      .join(", ")
  )

  useEffect(() => {
    setInputValue(
      items
        .filter((i) => props.value?.includes(i.value))
        .map((i) => i.label)
        .join(", ")
    )
  }, [props.value, items])

  return (
    <div ref={containerRef}>
      <Popover
        open={open}
        onOpenChange={(isOpen): void => {
          setOpen(isOpen)
          const nonSelected = comboItems.filter(
            (i) => !selected.map((s) => s.value).includes(i.value)
          )
          sticky && setComboItems([...selected, ...nonSelected])
        }}
      >
        <PopoverTrigger asChild>
          <button
            role="combobox"
            aria-controls="combobox-content"
            aria-expanded={open}
            className={cn(
              buttonVariants({ size, variant: "outline" }),
              hasError &&
                "!border-danger-main !focus-visible:border-danger-main",
              "h-11 w-full cursor-pointer justify-between border-input bg-background text-sm font-light shadow-none ring-offset-background placeholder:text-muted-foreground hover:bg-transparent",
              className
            )}
            ref={triggerRef}
            {...props}
          >
            <div className="truncate">
              {inputValue.length > 0 ? (
                inputValue
              ) : (
                <span>{inputPlaceholder ?? "Selecciona de la lista"}</span>
              )}
            </div>
            <div
              className={cn(
                "flex items-center justify-end gap-2 pl-1",
                selected.length > 0 ? "basis-[68px]" : "basis-[32px]"
              )}
            >
              {selected.length > 0 ? (
                <>
                  <Badge variant="outline">{selected.length}</Badge>
                  <Icons.xCircle
                    className="flex size-4 items-center justify-center opacity-50"
                    onClick={(): void => {
                      setInputValue("")
                      onChange([])
                    }}
                  />
                </>
              ) : (
                <Icons.chevronDown className="flex size-4 items-center justify-center opacity-50" />
              )}
            </div>
          </button>
        </PopoverTrigger>
        <PopoverContent
          className={cn("p-0")}
          style={{ width: containerRef.current?.offsetWidth }}
          container={containerRef.current}
        >
          <Command>
            <CommandInput
              placeholder={searchPlaceholder ?? "Buscar en la lista ..."}
              hide={hideSearch}
            />
            <ScrollArea className={cn(items.length >= 7 && "mt-2 h-64")}>
              <CommandEmpty>
                {emptyPlaceholder ?? "No se encontraron resultados"}
              </CommandEmpty>
              <CommandGroup>
                {comboItems.map((item) => (
                  <CommandItem
                    key={item.value}
                    value={item.label}
                    onSelect={(): void => {
                      let newSelected: ComboboxItem[] = []
                      if (selected.map((s) => s.value).includes(item.value)) {
                        newSelected = selected.filter(
                          (i) => i.value !== item.value
                        )
                      } else {
                        newSelected = [...selected, item]
                      }
                      setInputValue(newSelected.map((s) => s.label).join(", "))
                      onChange(newSelected)
                    }}
                    className="mr-4 cursor-pointer select-none items-center rounded-lg py-3 pl-6 outline-none data-[disabled]:pointer-events-none data-[disabled]:opacity-20"
                  >
                    <Icons.check
                      className={cn(
                        "mr-2 size-4",
                        selected.map((s) => s.value).includes(item.value)
                          ? "opacity-100"
                          : "opacity-0"
                      )}
                    />
                    <div className="text-sm">{item.label}</div>
                  </CommandItem>
                ))}
              </CommandGroup>
            </ScrollArea>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  )
}
