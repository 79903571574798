import { Label } from "@/components/ui/label"
import { Toggle } from "@/components/ui/toggle"
import { Icons } from "@/components/icons"

import { ExtraItem } from "../extras-picker"

export type ExtrasPickerProps = {
  items: ExtraItem[]
  onPressedChange: (item: ExtraItem) => void
}

export function CommonExtrasPicker({
  items,
  onPressedChange,
}: ExtrasPickerProps) {
  return (
    <div>
      <Label className="block pb-2">Características</Label>
      <div className="flex flex-col gap-y-3">
        {items.map((item) => {
          const Icon = Icons[item.icon] ?? "chevronLeft"
          return (
            <Toggle
              key={item.key}
              variant="outline"
              size="lg"
              className="flex w-full items-center justify-start gap-x-4 pl-6"
              pressed={item.value}
              onPressedChange={() => onPressedChange(item)}
            >
              <Icon className="size-5" />
              <p className="text-left">{item.label}</p>
            </Toggle>
          )
        })}
      </div>
    </div>
  )
}
