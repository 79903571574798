"use client"

import * as React from "react"
import { NumericFormat, type NumericFormatProps } from "react-number-format"

import { cn } from "@/lib/utils"
import { buttonVariants } from "@/components/ui/button"
import { Input } from "@/components/ui/input"

interface NumericInputProps extends NumericFormatProps {}

const NumericInput = React.forwardRef<HTMLInputElement, NumericInputProps>(
  (
    {
      className,
      suffix,
      inputMode = "numeric",
      pattern = "^[0-9.,]+$",
      value,
      ...props
    },
    ref
  ) => {
    return (
      <div className="relative">
        <NumericFormat
          thousandSeparator={","}
          decimalSeparator={"."}
          decimalScale={0}
          className={cn(suffix && "pr-10", className)}
          getInputRef={ref}
          customInput={Input}
          allowNegative={false}
          value={value === undefined ? "" : value}
          inputMode={inputMode}
          pattern={pattern}
          {...props}
        />

        {suffix ? (
          <div
            className={cn(
              buttonVariants({ variant: "ghost", size: "sm" }),
              "absolute right-0 top-0 h-full cursor-auto px-3 py-1 hover:bg-transparent"
            )}
            aria-disabled={true}
          >
            <span className="h-4 text-sm">{suffix}</span>
          </div>
        ) : null}
      </div>
    )
  }
)
NumericInput.displayName = "NumericInput"

export { NumericInput }
