"use client"

import React, { useEffect } from "react"
import { SearchParamsKeyEnum } from "@/types"

import { SearchSearchParamsSchemaType } from "@/lib/validations/params"
import { Label } from "@/components/ui/label"
import { MultiSelectCombobox } from "@/components/ui/multi-select-combobox"

type FilterSelectProps = {
  label: string
  configItems: {
    key: string
    label: string
  }[]
  filterType: "commercialLocation" | "commercialActivity" | "condition"
  searchParams: SearchSearchParamsSchemaType
  onChange: (
    keys: string[],
    searchParamKey: "estado" | "ubicacion_comercial" | "actividad_comercial"
  ) => void
  searchable?: boolean
}

export function FilterMultiSelect({
  label,
  configItems,
  filterType,
  searchParams,
  onChange,
  searchable = false,
}: FilterSelectProps) {
  const [selectedItems, setSelectedItems] = React.useState<string[]>(
    searchParams[SearchParamsKeyEnum.enum[filterType]]?.split(",") ?? []
  )

  const handleOnSelect = (keys: string[]) => {
    onChange(keys, SearchParamsKeyEnum.enum[filterType])
    setSelectedItems(keys)
  }

  useEffect(() => {
    setSelectedItems(
      searchParams[SearchParamsKeyEnum.enum[filterType]]?.split(",") ?? []
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <Label className="pb-2">{label}</Label>
      <div className="m-1">
        <MultiSelectCombobox
          items={configItems.map((item) => ({
            value: item.key,
            label: item.label,
          }))}
          selected={selectedItems.map((item) => ({
            value: item,
            label: configItems.find((i) => i.key === item)?.label ?? "",
          }))}
          value={selectedItems}
          onChange={(items) => handleOnSelect(items?.map((i) => i.value))}
          inputPlaceholder={label}
          searchPlaceholder={label}
          emptyPlaceholder={label}
          hideSearch={!searchable}
          size="sm"
        />
      </div>
    </div>
  )
}
