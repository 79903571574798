"use client"

import React, { useId } from "react"
import {
  ApartmentTypeFeaturesEnum,
  DepositFeaturesEnum,
  FurnishedFeaturesEnum,
  PropertyTypeEnum,
  TerrainTypeFeaturesEnum,
  ViewFeaturesEnum,
} from "@/db/schemas/enums"
import { SearchParamsKeyEnum } from "@/types"

import { capitalize } from "@/lib/utils"
import { SearchSearchParamsSchemaType } from "@/lib/validations/params"
import { Checkbox } from "@/components/ui/checkbox"
import { Label } from "@/components/ui/label"

type CheckboxGroupPickerProps = {
  searchParams: SearchSearchParamsSchemaType
  filterType:
    | "apartmentType"
    | "furnished"
    | "views"
    | "deposit"
    | "terrainType"
  onChange: (
    checkedItems: string[],
    searchParamKey: SearchParamsKeyEnum
  ) => void
  propertyType?: PropertyTypeEnum
}

export function CheckboxGroupPicker({
  searchParams,
  filterType,
  onChange,
  propertyType,
}: CheckboxGroupPickerProps) {
  const id = useId()
  const getParamKey = React.useMemo(() => {
    switch (filterType) {
      case "apartmentType":
        return SearchParamsKeyEnum.enum.apartmentType
      case "furnished":
        return SearchParamsKeyEnum.enum.furnished
      case "views":
        return SearchParamsKeyEnum.enum.views
      case "deposit":
        return SearchParamsKeyEnum.enum.deposit
      case "terrainType":
        return SearchParamsKeyEnum.enum.terrainType
    }
  }, [filterType])

  const [checkedItems, setCheckedItems] = React.useState<string[]>([
    ...(searchParams[getParamKey]?.split(",") ?? ""),
  ])

  const renderFilterLabel = React.useMemo(() => {
    switch (filterType) {
      case "apartmentType":
        return "Tipo de Departamento"
      case "furnished":
        return "Amoblado"
      case "views":
        return "Vistas"
      case "deposit":
        return "Meses de garantía"
      case "terrainType":
        return "Tipo de terreno"
    }
  }, [filterType])

  const getEnumValues = React.useMemo(() => {
    switch (filterType) {
      case "apartmentType":
        return ApartmentTypeFeaturesEnum.Values
      case "furnished":
        return propertyType === PropertyTypeEnum.Values.oficina
          ? [
              FurnishedFeaturesEnum.Values.amoblado,
              FurnishedFeaturesEnum.Values["sin-amoblar"],
              FurnishedFeaturesEnum.Values["semi-amoblado"],
            ]
          : FurnishedFeaturesEnum.Values
      case "views":
        return ViewFeaturesEnum.Values
      case "deposit":
        return DepositFeaturesEnum.Values
      case "terrainType":
        return TerrainTypeFeaturesEnum.Values
    }
  }, [filterType, propertyType])

  const renderLabel = (label: string) => {
    return capitalize(label.split("-").join(" "))
  }

  return (
    <div>
      <Label className="mb-2 block">{renderFilterLabel}</Label>
      <div className="flex flex-col gap-y-4">
        {Object.values(getEnumValues).map((value) => (
          <div key={value} className="flex items-center gap-x-2">
            <Checkbox
              id={`checkbox-${value}-${id}`}
              checked={checkedItems.includes(value)}
              onCheckedChange={(checked) => {
                checked
                  ? onChange([...checkedItems, value], getParamKey)
                  : onChange(
                      checkedItems.filter((item) => item !== value),
                      getParamKey
                    )
                checked
                  ? setCheckedItems((prev) => [...prev, value])
                  : setCheckedItems((prev) => [
                      ...prev.filter((item) => item !== value),
                    ])
              }}
            />
            <Label htmlFor={`checkbox-${value}-${id}`}>
              {renderLabel(value)}
            </Label>
          </div>
        ))}
      </div>
    </div>
  )
}
