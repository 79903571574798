"use client"

import React, { useCallback, useEffect } from "react"
import {
  ExtrasFeaturesEnum,
  PropertyTypeEnum,
  TransactionTypeEnum,
} from "@/db/schemas/enums"

import { SearchConfig } from "@/config/search-config"
import { SearchSearchParamsSchemaType } from "@/lib/validations/params"
import { Icons } from "@/components/icons"
import { getExtrasAction } from "@/app/_actions/features"

import { CommonExtrasPicker } from "./extras-picker/common-extras-picker"
import { OfficeExtrasPicker } from "./extras-picker/office-extras-picker"

export type ExtraItem = {
  index: number
  key: ExtrasFeaturesEnum
  label: string
  value: boolean
  icon: keyof typeof Icons
}

type ExtrasPickerProps = {
  searchParams: SearchSearchParamsSchemaType
  transactionType: TransactionTypeEnum
  propertyType: PropertyTypeEnum
  onChange: (items: ExtraItem[]) => void
}

export function ExtrasPicker({
  searchParams,
  transactionType,
  propertyType,
  onChange,
}: ExtrasPickerProps) {
  const extrasKeysParams = searchParams.extras
  const extrasKeys = extrasKeysParams?.split(",")

  const [items, setItems] = React.useState<ExtraItem[]>([])

  function handlePressedChange(clickedItem: ExtraItem) {
    onChange([
      ...items.map((item) =>
        item.key === clickedItem.key
          ? { ...item, value: !item.value }
          : { ...item }
      ),
    ])
    setItems((prev) => [
      ...prev.map((item) =>
        item.key === clickedItem.key
          ? { ...item, value: !item.value }
          : { ...item }
      ),
    ])
  }

  const formatExtraItems = useCallback(
    (extrasFeatures: ExtrasFeaturesEnum[]): ExtraItem[] => {
      return extrasFeatures.map((key, index) => ({
        index,
        key,
        label:
          SearchConfig.extrasConfig.find((val) => val.key === key)?.label ?? "",
        value: extrasKeys?.includes(key) ?? false,
        icon:
          SearchConfig.extrasConfig.find((val) => val.key === key)?.icon ??
          "add",
      }))
    },
    [extrasKeys]
  )

  useEffect(() => {
    getExtrasAction(propertyType, transactionType).then((extras) => {
      const filteredExtrasEnum = Object.values(
        ExtrasFeaturesEnum.Values
      ).filter((key) => extras.some((extra) => extra.key === key))
      const formattedItems = formatExtraItems(filteredExtrasEnum)
      setItems(formattedItems)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyType, transactionType])

  return propertyType === PropertyTypeEnum.Values.oficina ? (
    <OfficeExtrasPicker items={items} onPressedChange={handlePressedChange} />
  ) : (
    <CommonExtrasPicker items={items} onPressedChange={handlePressedChange} />
  )
}
