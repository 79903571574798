import { AreaTypeEnum, PropertyTypeEnum } from "@/db/schemas/enums"

import { SearchSearchParamsSchemaType } from "@/lib/validations/params"

import { config } from "./config"

export function getAreaType(propertyType?: PropertyTypeEnum): AreaTypeEnum {
  switch (propertyType) {
    case PropertyTypeEnum.Enum.casa:
    case PropertyTypeEnum.Enum.departamento:
    case PropertyTypeEnum.Enum.oficina:
      return "techado"
    case PropertyTypeEnum.Enum.terreno:
      return "edificable"
    case PropertyTypeEnum.Enum["local-comercial"]:
      return "útil"
    default:
      return "total"
  }
}

export function getInitialUnit(
  type: "price" | "area",
  searchParams: SearchSearchParamsSchemaType,
  propertyType?: PropertyTypeEnum
) {
  return type === "price"
    ? searchParams?.divisa === "soles"
      ? "soles"
      : searchParams?.divisa
        ? "dolares"
        : "soles"
    : searchParams?.area === "total"
      ? "total"
      : searchParams?.area
        ? getAreaType(propertyType)
        : "total"
}

export function getInitialMinValue(
  type: "price" | "area",
  searchParams: SearchSearchParamsSchemaType
) {
  return type === "price"
    ? String(searchParams?.precio_min)
    : String(searchParams?.area_min)
}

export function getInitialMinValueInput(
  type: "price" | "area",
  searchParams: SearchSearchParamsSchemaType,
  transactionType: "venta" | "alquiler",
  unit: "soles" | "dolares" | AreaTypeEnum
) {
  return type === "price"
    ? config?.["price"]?.[transactionType]?.[
        unit === "soles" ? "soles" : "dolares"
      ]?.findIndex((i) => i.value === searchParams?.precio_min) !== -1
      ? undefined
      : String(searchParams?.precio_min)
    : config?.["area"]?.[transactionType]?.[
          unit === "total" ? "total" : "techado"
        ]?.findIndex((i) => i.value === searchParams?.area_min) !== -1
      ? undefined
      : String(searchParams?.area_min)
}

export function getInitialMaxValue(
  type: "price" | "area",
  searchParams: SearchSearchParamsSchemaType
) {
  return type === "price"
    ? String(searchParams?.precio_max)
    : String(searchParams?.area_max)
}

export function getInitialMaxValueInput(
  type: "price" | "area",
  searchParams: SearchSearchParamsSchemaType,
  transactionType: "venta" | "alquiler",
  unit: "soles" | "dolares" | AreaTypeEnum
) {
  return type === "price"
    ? config?.["price"]?.[transactionType]?.[
        unit === "soles" ? "soles" : "dolares"
      ]?.findIndex((i) => i.value === searchParams?.precio_max) !== 1
      ? undefined
      : String(searchParams?.precio_max)
    : config?.["area"]?.[transactionType]?.[
          unit === "total" ? "total" : "techado"
        ]?.findIndex((i) => i.value === searchParams?.area_max) !== -1
      ? undefined
      : String(searchParams?.area_max)
}
