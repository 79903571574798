import { Feature } from "@/db/schemas"
import { ExtrasFeaturesEnum } from "@/db/schemas/enums"

import { ExtraItem } from "../search/components/extras-picker"

const officeCommonAreasKeys = [
  ExtrasFeaturesEnum.Values.recepcion,
  ExtrasFeaturesEnum.Values["area-cafe"],
  ExtrasFeaturesEnum.Values.comedor,
  ExtrasFeaturesEnum.Values["cocina-completamente-equipada"],
  ExtrasFeaturesEnum.Values.terraza,
  ExtrasFeaturesEnum.Values["sala-multiusos"],
]

const officeSecurityKeys = [
  ExtrasFeaturesEnum.Values["sistema-de-alarma"],
  ExtrasFeaturesEnum.Values["detectores-de-humo"],
  ExtrasFeaturesEnum.Values.vigilancia,
  ExtrasFeaturesEnum.Values.videovigilancia,
]

const officeCommoditiesKeys = [
  ExtrasFeaturesEnum.Values["adaptado-movilidad-reducida"],
  ExtrasFeaturesEnum.Values.ascensor,
  ExtrasFeaturesEnum.Values["aire-acondicionado"],
  ExtrasFeaturesEnum.Values["estacionamiento-bicicletas"],
]

export const getOfficeExtrasByCategory = (items: (ExtraItem | Feature)[]) => {
  const commonAreasExtras = items.filter((f) =>
    officeCommonAreasKeys.some((v) => v === f.key)
  )
  const securityExtras = items.filter((f) =>
    officeSecurityKeys.some((v) => v === f.key)
  )
  const commoditiesExtras = items.filter((f) =>
    officeCommoditiesKeys.some((v) => v === f.key)
  )

  return {
    commonAreasExtras,
    securityExtras,
    commoditiesExtras,
  }
}

// -------------- Habitación en vivienda compartida --------------

const sharedHouseKeys = [
  ExtrasFeaturesEnum.Values["terraza"],
  ExtrasFeaturesEnum.Values["aire-acondicionado"],
  ExtrasFeaturesEnum.Values["internet"],
  ExtrasFeaturesEnum.Values["jardin"],
  ExtrasFeaturesEnum.Values["cocina-completamente-equipada"],
  ExtrasFeaturesEnum.Values["limpieza-incluida"],
  ExtrasFeaturesEnum.Values["adaptado-movilidad-reducida"],
  ExtrasFeaturesEnum.Values["lgtb-friendly"],
]

const sharedBuildingKeys = [
  ExtrasFeaturesEnum.Values["ascensor"],
  ExtrasFeaturesEnum.Values["piscina"],
  ExtrasFeaturesEnum.Values["areas-comunes"],
]

export const getSharedBedroomExtrasByCategory = (
  items: (ExtraItem | Feature)[]
) => {
  const sharedHouseFeatures = items.filter((f) =>
    sharedHouseKeys.some((v) => v === f.key)
  )
  const sharedBuildingFeatures = items.filter((f) =>
    sharedBuildingKeys.some((v) => v === f.key)
  )

  return {
    sharedHouseFeatures,
    sharedBuildingFeatures,
  }
}
