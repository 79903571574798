import { Label } from "@/components/ui/label"
import { getOfficeExtrasByCategory } from "@/modules/publish-flow/utils"

import { ExtraItem } from "../extras-picker"
import { ExtrasPickerProps } from "./common-extras-picker"
import { ExtraToggle } from "./extra-toggle"

export function OfficeExtrasPicker({
  items,
  onPressedChange,
}: ExtrasPickerProps) {
  const { commonAreasExtras, securityExtras, commoditiesExtras } =
    getOfficeExtrasByCategory(items)

  return (
    <div className="flex flex-col gap-y-5">
      <div className="flex flex-col gap-y-3">
        <Label className="block pb-2">Áreas Comunes</Label>
        {commonAreasExtras.map((item) => (
          <ExtraToggle
            key={item.key}
            item={item as ExtraItem}
            onPressedChange={onPressedChange}
          />
        ))}
      </div>
      <div className="flex flex-col gap-y-3">
        <Label className="block pb-2">Seguridad</Label>
        {securityExtras.map((item) => (
          <ExtraToggle
            key={item.key}
            item={item as ExtraItem}
            onPressedChange={onPressedChange}
          />
        ))}
      </div>
      <div className="flex flex-col gap-y-3">
        <Label className="block pb-2">Otros Servicios</Label>
        {commoditiesExtras.map((item) => (
          <ExtraToggle
            key={item.key}
            item={item as ExtraItem}
            onPressedChange={onPressedChange}
          />
        ))}
      </div>
    </div>
  )
}
