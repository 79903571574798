import {
  ApartmentTypeFeaturesEnum,
  AreaTypeEnum,
  AvailableFromEnum,
  BedroomBathroomFeaturesEnum,
  BedroomIsFurnishedFeaturesEnum,
  CommercialActivityEnum,
  CommercialLocationEnum,
  CouplesAllowedFeaturesEnum,
  DepositFeaturesEnum,
  DesiredGenderEnum,
  ExtrasFeaturesEnum,
  FurnishedFeaturesEnum,
  MinorsAllowedFeaturesEnum,
  PetsFeaturesEnum,
  PropertyConditionEnum,
  SmokersFeaturesEnum,
  TerrainTypeFeaturesEnum,
  TransactionTypeEnum,
  // TypeOfBedFeaturesEnum,
  ViewFeaturesEnum,
} from "@/db/schemas/enums"
import {
  SearchParamsKeyEnum,
  SortOptionEnum,
  YourAdsParamsKeyEnum,
} from "@/types"
import { z } from "zod"

import { statusConfig } from "@/config/status-config"
import { zodFallback } from "@/lib/utils"
import { userPublicMetadataSchema } from "@/lib/validations/metadata"

export const searchSearchParamsSchema = z.object({
  [SearchParamsKeyEnum.enum.page]: zodFallback(z.string().default("1"), "1"),
  [SearchParamsKeyEnum.enum.perPage]: zodFallback(
    z.string().default("10"),
    "10"
  ),
  [SearchParamsKeyEnum.enum.apartmentType]: zodFallback(
    z.string().refine((param) => {
      const types = param.split(",")
      for (const type of types) {
        return ApartmentTypeFeaturesEnum.parse(type)
      }
    }),
    null
  ),
  [SearchParamsKeyEnum.enum.furnished]: zodFallback(
    z.string().refine((param) => {
      const types = param.split(",")
      for (const type of types) {
        return FurnishedFeaturesEnum.parse(type)
      }
    }),
    null
  ),
  [SearchParamsKeyEnum.enum.views]: zodFallback(
    z.string().refine((param) => {
      const types = param.split(",")
      for (const type of types) {
        return ViewFeaturesEnum.parse(type)
      }
    }),
    null
  ),
  [SearchParamsKeyEnum.enum.deposit]: zodFallback(
    z.string().refine((param) => {
      const types = param.split(",")
      for (const type of types) {
        return DepositFeaturesEnum.parse(type)
      }
    }),
    null
  ),
  [SearchParamsKeyEnum.enum.currency]: zodFallback(
    z.enum(["soles", "dolares"]),
    null
  ),
  [SearchParamsKeyEnum.enum.minPrice]: zodFallback(
    z.coerce.number().int().positive().optional(),
    null
  ),
  [SearchParamsKeyEnum.enum.maxPrice]: zodFallback(
    z.coerce.number().int().optional().optional(),
    null
  ),
  [SearchParamsKeyEnum.enum.area]: zodFallback(AreaTypeEnum, null),
  [SearchParamsKeyEnum.enum.minArea]: zodFallback(
    z.coerce.number().int().positive().optional(),
    null
  ),
  [SearchParamsKeyEnum.enum.maxArea]: zodFallback(
    z.coerce.number().int().positive().optional(),
    null
  ),
  [SearchParamsKeyEnum.enum.terrainType]: zodFallback(
    z.string().refine((param) => {
      const types = param.split(",")
      for (const type of types) {
        return TerrainTypeFeaturesEnum.parse(type)
      }
    }),
    null
  ),
  [SearchParamsKeyEnum.enum.features]: zodFallback(z.string(), null),
  [SearchParamsKeyEnum.enum.extras]: zodFallback(
    z.string().refine((param) => {
      const extras = param.split(",")
      for (const extra of extras) {
        return ExtrasFeaturesEnum.parse(extra)
      }
    }),
    null
  ),
  [SearchParamsKeyEnum.enum.placeId]: zodFallback(z.string(), null),
  [SearchParamsKeyEnum.enum.sort]: zodFallback(SortOptionEnum, null),
  [SearchParamsKeyEnum.enum.commercialLocation]: zodFallback(
    z.string().refine((param) => {
      const extras = param.split(",")
      for (const extra of extras) {
        return CommercialLocationEnum.parse(extra)
      }
    }),
    null
  ),
  [SearchParamsKeyEnum.enum.commercialActivity]: zodFallback(
    z.string().refine((param) => {
      const extras = param.split(",")
      for (const extra of extras) {
        return CommercialActivityEnum.parse(extra)
      }
    }),
    null
  ),
  [SearchParamsKeyEnum.enum.condition]: zodFallback(
    PropertyConditionEnum,
    null
  ),
  [SearchParamsKeyEnum.enum.smokers]: zodFallback(SmokersFeaturesEnum, null),
  [SearchParamsKeyEnum.enum.pets]: zodFallback(PetsFeaturesEnum, null),
  // [SearchParamsKeyEnum.enum.typeOfBed]: zodFallback(
  //   TypeOfBedFeaturesEnum,
  //   null
  // ),
  [SearchParamsKeyEnum.enum.bedroomIsFurnished]: zodFallback(
    BedroomIsFurnishedFeaturesEnum,
    null
  ),
  [SearchParamsKeyEnum.enum.bedroomBathroom]: zodFallback(
    BedroomBathroomFeaturesEnum,
    null
  ),
  [SearchParamsKeyEnum.enum.couples]: zodFallback(
    CouplesAllowedFeaturesEnum,
    null
  ),
  [SearchParamsKeyEnum.enum.minors]: zodFallback(
    MinorsAllowedFeaturesEnum,
    null
  ),
  [SearchParamsKeyEnum.enum.availableFrom]: zodFallback(
    AvailableFromEnum,
    null
  ),
  [SearchParamsKeyEnum.enum.desiredGender]: zodFallback(
    DesiredGenderEnum,
    null
  ),
})
export type SearchSearchParamsSchemaType = z.infer<
  typeof searchSearchParamsSchema
>

export const yourAdsSearchParamsSchema = z.object({
  [YourAdsParamsKeyEnum.enum.transactionType]: zodFallback(
    TransactionTypeEnum,
    undefined
  ),
  [YourAdsParamsKeyEnum.enum.status]: zodFallback(
    z
      .enum(
        Object.values(statusConfig).map((c) => c.slug) as [string, ...string[]]
      )
      .optional(),
    null
  ),
})

export const signUpSearchParamsSchema = z.object({
  membershipPlan: zodFallback(
    userPublicMetadataSchema.shape.membershipPlan,
    null
  ),
  __clerk_ticket: z.string().optional(),
  role: z.enum(["basic_member", "admin"]).optional(),
  emailAddress: z.string().optional(),
})

export const invitationCallbackSearchParamsSchema = z.object({
  __clerk_ticket: z.string(),
  __clerk_status: z.enum(["sign_in", "sign_up", "complete"]),
  role: z.enum(["basic_member", "admin"]),
  emailAddress: z.string(),
})
