import { PropertyStatusEnum } from "@/db/schemas/enums"

export const statusConfig: {
  [key in PropertyStatusEnum]: {
    bgColor: string
    dotColor: string
    text: string
    slug: string
  }
} = {
  [PropertyStatusEnum.Values.active]: {
    bgColor: "bg-green-500/10",
    dotColor: "bg-green-500",
    text: "Activo",
    slug: "activo",
  },
  [PropertyStatusEnum.Values["need-changes"]]: {
    bgColor: "bg-purple-500/10",
    dotColor: "bg-purple-500",
    text: "Necesita Cambios",
    slug: "necesita-cambios",
  },
  [PropertyStatusEnum.Values.draft]: {
    bgColor: "bg-foreground/10",
    dotColor: "bg-foreground",
    text: "Borrador",
    slug: "borrador",
  },
  [PropertyStatusEnum.Values.hidden]: {
    bgColor: "bg-orange-300/30",
    dotColor: "bg-orange-300",
    text: "Oculto",
    slug: "oculto",
  },
  [PropertyStatusEnum.Values["in-review"]]: {
    bgColor: "bg-yellow-300/30",
    dotColor: "bg-yellow-300",
    text: "En Revisión",
    slug: "en-revision",
  },
  [PropertyStatusEnum.Values.finalized]: {
    bgColor: "bg-blue-500/30",
    dotColor: "bg-blue-500",
    text: "Finalizado",
    slug: "caducado",
  },
  [PropertyStatusEnum.Values.rejected]: {
    bgColor: "bg-foreground/05",
    dotColor: "bg-foreground",
    text: "Descartado",
    slug: "Descartado",
  },
  // TODO: Pensar esto
  // This will never happen, but it's here to avoid TS errors
  [PropertyStatusEnum.Values.deleted]: {
    bgColor: "bg-red-500/30",
    dotColor: "bg-red-500",
    text: "Borrado",
    slug: "borrado",
  },
}
